






























































































































































import VueBase from '@/VueBase'
import { Component, Emit } from 'vue-property-decorator'
@Component({
  name: 'SearchBar',
})
export default class SearchBar extends VueBase {
  private searchType = ''
  private searchValue = ''
  private showSelect = false
  public search_mode = 1
  private timeStep = 15
  private timeUnit = '60'
  private timeNode = '1'
  public time_range = [
    new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 7),
    new Date(),
  ]
  @Emit('enter')
  private enterSearch() {
    return [this.searchType, this.searchValue]
  }

  private enterTime() {
    if (this.timeStep <= 0) {
      this.$message.warning(this.$t('views.search.timeWarning') as string)
      return
    }
    if (this.timeNode === '1') {
      this.time_range = [
        new Date(
          new Date().getTime() - this.timeStep * Number(this.timeUnit) * 1000
        ),
        new Date(),
      ]
    } else {
      this.services.taint.timerange().then((res) => {
        if (res.status === 201) {
          this.time_range = [
            new Date(res.data * 1000),
            new Date(
              res.data * 1000 + this.timeStep * Number(this.timeUnit) * 1000
            ),
          ]
        } else {
          this.time_range = [
            new Date(
              new Date().getTime() -
                this.timeStep * Number(this.timeUnit) * 1000
            ),
            new Date(),
          ]
        }
      })
    }
    this.showSelect = false
  }

  private getDate(time: string) {
    if (time === 'day') {
      this.time_range = [this.getNowDayStar(), this.getNowDayEnd()]
      this.showSelect = false

      return
    }
    if (time === 'week') {
      this.time_range = [this.getStartDayOfWeek(), this.getEndDayOfWeek()]
      this.showSelect = false
      return
    }
    const now = new Date()
    const end = new Date(now.getTime() - Number(time))
    this.time_range = [end, now]
    this.showSelect = false
  }

  now = new Date() // 当前日期
  nowDayOfWeek = this.now.getDay() // 今天本周的第几天
  nowDay = this.now.getDate() // 当前日
  nowMonth = this.now.getMonth() // 当前月

  getNowDayStar() {
    return new Date(this.now.getFullYear(), this.nowMonth, this.nowDay, 0, 0, 0)
  }
  getNowDayEnd() {
    return new Date(
      this.now.getFullYear(),
      this.nowMonth,
      this.nowDay,
      23,
      59,
      59
    )
  }
  getStartDayOfWeek() {
    var day = this.nowDayOfWeek || 7
    return new Date(
      this.now.getFullYear(),
      this.nowMonth,
      this.nowDay + 1 - day
    )
  }
  getEndDayOfWeek() {
    var day = this.nowDayOfWeek || 7
    return new Date(
      this.now.getFullYear(),
      this.nowMonth,
      this.nowDay + 7 - day,
      23,
      59,
      59
    )
  }
}
